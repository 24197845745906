import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery } from "gatsby"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/ministries.css"
import "../../styles/mary-and-marthas.css"
import JSONData from "../../../content/mary-and-marthas/data.json"
import OrgStructure from "../../../static/pdf/mary-and-marthas/Organizational Structure of Sisterhood.pdf"
import ProposalForm from "../../../static/pdf/mary-and-marthas/Request and Proposal Form.pdf"
import Bylaws from "../../../static/pdf/mary-and-marthas/Leadership Committee Bylaws.pdf"
import Prayer from "../../../static/pdf/mary-and-marthas/Sisters M & M General Meeting Prayers.pdf"


function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          filter: { relativePath: { glob: "images/pages/mary-and-marthas/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
	    }
	`






	let data = useStaticQuery(query)

	let images = data.allFile
	let sidebarData = JSONData.sidebarTextblocks;
	let subheaderData = JSONData.subheader; 

	function getLeadershipElement(element,key){
		if(element.title){
			return <li key={key}><b>{element.title}</b> - {element.name}</li>
		} else {
			return <li key={key}>{element.name}</li>
		}
	}

	let leadershipBlock = JSONData.leadership.map((element,key) => getLeadershipElement(element,key))
	
	const sidebarImage = images.edges.find(element => element.node.name === 'martha-and-mary-icon').node
	const firstPic = images.edges.find(element => element.node.name === 'Kh. Barb').node
	const secondPic = images.edges.find(element => element.node.name === 'basement').node
	const location = ""
	

	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<div className="line-across menu-line-across"></div>
			<main className="site-content mary-and-marthas">
				<div>
					<Subheader data={subheaderData} />
				</div>

					<Sidebar pageHome="true" textData={sidebarData} links={{}} image={sidebarImage}/>
					<div className="content">
					<div className="top-photos">
						<Img className="top-photo" fluid={secondPic.childImageSharp.fluid}/>
						<Img className="top-photo" fluid={firstPic.childImageSharp.fluid}/>
					</div>
						<p>Since the beginning days of Jesus' ministry women have played an indispensable role in the Christian fellowship. In our Antiochian  Archdiocese, each parish is asked to organize a 'sisterhood' to help facilitate communication, fellowship, and service among the women. This sisterhood is known as the 'Antiochian Women' and they have the opportunity to correspond with other sisterhoods on a regional and national level in our Archdiocese. At Saint John's our women have also taken the name <b>'The Sisters of Mary and Martha.'</b> We are greatly blessed by the work of the women in our parish and grateful to God for all they do.</p>
					<div className="leadership-committee">
						<h3>Leadership Committee</h3>
						<ul>
							{leadershipBlock}
							</ul>
					</div>
					<div className="download-btns">
						<div><a className="download-btn " href={OrgStructure} target="_blank">Organizational Structure of Sisterhood</a></div>
						<div><a className="download-btn " href={ProposalForm} target="_blank">Request and Proposal Form</a></div>
						<div><a className="download-btn " href={Bylaws} target="_blank">Leadership Committee Bylaws</a></div>
						<div><a className="download-btn " href={Prayer} target="_blank">General Meeting Prayers</a></div>
					</div>
					</div>
			</main>
			<Footer />		
		</div>
		)
}

export default ERI 